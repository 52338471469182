import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import Favicon from "react-favicon";

import "./Styles/styles.scss";

import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import ErrorNotFound from "./Components/ErrorNotFound";

import Home from "./Pages/Home";
import Prices from "./Pages/Prices";
import Services from "./Pages/Services";
import Documents from "./Pages/Documents";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />} errorElement={<ErrorNotFound />}>
        <Route index element={<Home lang="hu" />} />

        <Route path="/hu">
          <Route index element={<Home lang="hu" />} />
          <Route path="arak" element={<Prices lang="hu" />} />
          <Route path="szolgaltatasok" element={<Services lang="hu" />} />
          <Route path="dokumentumok" element={<Documents lang="hu" />} />
        </Route>

        <Route path="/en">
          <Route index element={<Home lang="en" />} />
          <Route path="prices" element={<Prices lang="en" />} />
          <Route path="services" element={<Services lang="en" />} />
          <Route path="documents" element={<Documents lang="en" />} />
        </Route>
      </Route>
    )
  );

  return (
    <>
      <Favicon url={`${process.env.PUBLIC_URL}/img/szupereb-white.png`} />
      <div className="app">
        <RouterProvider router={router} />
      </div>
    </>
  );
}

const Root = () => {
  return (
    <>
      <Navbar />

      <main>
        <Outlet />
      </main>

      <Footer />
    </>
  );
};

export default App;
