import React from "react";

function ErrorNotFound() {
  setTimeout(() => {
    window.location.href = "/";
  }, 3000);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "95dvh",
      }}
      className="errorMessage"
    >
      <h1>Page not found!</h1>
    </div>
  );
}

export default ErrorNotFound;
