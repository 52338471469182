import React from "react";

function Footer() {
  const lang = window.location.href.split("/")[3];

  return (
    <footer className="footer">
      <div className="brand">
        <img
          src={`${process.env.PUBLIC_URL}/img/szupereb-white.png`}
          alt="SzuperEB"
        />
        <div className="text">
          <h2>SzuperEB</h2>
          <p>
            2023 © 2024{" "}
            {lang === "en" ? "All rights reserved." : "Minden jog fenntartva."}
          </p>
        </div>
      </div>
      <div className="documents">
        <h2>{lang === "en" ? "Documents" : "Dokumentumok"}</h2>
        <ul>
          <li>
            <a href={`${process.env.PUBLIC_URL}/docs/mukodesi_szabalyzat.pdf`}>
              {lang === "en" ? "Operation Policy" : "Működési Szabályzat"}
            </a>
          </li>
          <li>
            <a href={`${process.env.PUBLIC_URL}/docs/atveteli_adatlap.pdf`}>
              {lang === "en" ? "Dog Transfer Contract" : "Átvételi Adatlap"}
            </a>
          </li>
        </ul>
      </div>
      <div className="contact">
        <h2>{lang === "en" ? "Contact" : "Elérhetőség"}</h2>
        <ul>
          <li>
            <a href="mailto:szuperebek@gmail.com">szuperebek@gmail.com</a>
          </li>
          <li>
            <a href="tel:+36307886918">+36 (30) 788 6918</a>
          </li>
        </ul>
      </div>
      <div className="socials">
        <div className="socialMedia">
          <div className="facebook"></div>
          <div className="tiktok"></div>
        </div>
        <div className="credits">
          <div className="photos">
            {lang === "en" ? (
              <h6>
                Photos ©{" "}
                <a href="https://www.facebook.com/DocziHeniKutyakozmetikaesfoto">
                  Henrietta Dóczi
                </a>
              </h6>
            ) : (
              <h6>
                Fényképek ©{" "}
                <a href="https://www.facebook.com/DocziHeniKutyakozmetikaesfoto">
                  Dóczi Henrietta
                </a>
              </h6>
            )}
          </div>
          <div className="website">
            {lang === "en" ? (
              <h6>
                Website ©{" "}
                <a href="https://www.linkedin.com/in/patrik-hodoneczki-5244652b8/">
                  Patrik Hodoneczki
                </a>
              </h6>
            ) : (
              <h6>
                Weboldal ©{" "}
                <a href="https://www.linkedin.com/in/patrik-hodoneczki-5244652b8/">
                  Hodoneczki Patrik
                </a>
              </h6>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
