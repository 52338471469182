import React, { useState } from "react";

function Prices({ lang }) {
  return (
    <div className="prices">
      <div className="notice">
        <div className="title">
          <h2>
            {lang === "hu"
              ? "Utolsó módosítás: 2023. október 15."
              : "Last modified: 15 October 2023"}
          </h2>
        </div>
        <h6>
          {lang === "hu"
            ? "Részletes leírás megtalálható a "
            : "Detailed description can be found on our "}
          {lang === "hu" ? "Facebook oldalunkon" : "Facebook page"}
          <a
            href="https://www.facebook.com/permalink.php?story_fbid=pfbid0vv2WUD6wos3kFa7ci9gLViFDEGxB2F8m1xxjK8en9ek57btLiEtJQECVkpx8z8pyl&id=100091796694325"
            target="_blank"
            rel="noreferrer"
          >
            <a
              href="https://www.facebook.com/permalink.php?story_fbid=pfbid0vv2WUD6wos3kFa7ci9gLViFDEGxB2F8m1xxjK8en9ek57btLiEtJQECVkpx8z8pyl&amp;id=100091796694325"
              target="_blank"
              rel="noreferrer"
            >
              {lang === "hu" ? " ide kattintva" : " by clicking here"}
            </a>
          </a>
          .
        </h6>
      </div>
      <div className="information">
        <h3 style={{ fontWeight: "bold" }}>
          {lang === "hu" ? "Alapdíjak" : "Base fees"}
        </h3>
        <h4>
          {lang === "hu"
            ? "Panziós díj: 5000 Ft minden megkezdett napért."
            : "Boarding fee: 5000 HUF for every day started."}
        </h4>
        <h4>
          {lang === "hu"
            ? "Tartós panziónál (3 hetet meghaladó) és több kutyusnál kedvezménytadunk!"
            : "Boarding one of your dogs with us for a long time (3 weeks or more) or boarding multiple dogs with us, We'll give you a discount!"}
        </h4>
        <p>
          {lang === "hu" ? (
            "Érkezés és elvitel: kizárólag előre egyeztetett időpontban!"
          ) : (
            <>
              <h4>
                We only give out your dogs at an agreed time, which We can
                discuss on{" "}
                <a
                  href="https://www.facebook.com/profile.php?id=100091796694325"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
                .
              </h4>
            </>
          )}
        </p>
        <h4>
          {lang === "hu"
            ? "Napközi díja: 5000 Ft alkalmanként."
            : "Daycare fee: 5000 HUF per day."}
        </h4>
      </div>
      <div className="additional">
        <h3 style={{ fontWeight: "bold" }}>
          {lang === "hu" ? "Extra költségek" : "Additional fees"}
        </h3>
        <h4>
          {lang === "hu"
            ? "Kedd és szerda a kistestű kutyusok, péntek a nagyobbak napközije."
            : "Tuesday and Wednesday daycare is for the small, Friday is for the larger dogs."}
        </h4>
        <h6>
          {lang === "hu"
            ? "De megbeszélés szerint bármelyik napon meg tudjuk oldani a kutyus napközis ellátását, illetve vállalunk egyéni foglalkozást is, pl. félőseknél!"
            : "On agreement, We can arrange daycare for the dog on any day, and We also undertake individual sessions."}
        </h6>
        <h4>
          {lang === "hu"
            ? "Nem szobatiszta kutyára napi 500 Ft a felár!"
            : "We charge an additional 500 HUF per day for dogs that are not house-trained."}
        </h4>
        <h6>
          {lang === "hu"
            ? "(A rengeteg jelölgetés miatti extra takarítás és a más ivaros kanokkal való összeférhetetlenségük miatt kevesebb kutyust tudunk ilyenkor vállalni)"
            : "(Because of the extra cleaning that required due to the large number of markings and their incompatibility with other males, We can accept fewer dogs at this time)"}
        </h6>
        <h4>
          {lang === "hu"
            ? "A pirosbetűs ünnepeken a napidíj: 10.000 Ft"
            : "We charge and extra 5.000 HUF on holidays."}
        </h4>
        {lang === "en" && (
          <h6>
            (5.000 by default, plus an additional 5.000 is 10.000 HUF for each
            day of a holiday)
          </h6>
        )}
        <h4>
          {lang === "hu"
            ? "Ivaros kanoknál napi 1000 Ft felárat számolunk!"
            : "We also charge an additional 1000 Ft per day if you have a male dog that isn't neutered."}
        </h4>
      </div>
      {lang === "hu" ? (
        <h4>
          Egyéb szolgáltatásainkról (fotózás, kutyusok szállítása háztól-házig,
          kozmetika) érdeklődni{" "}
          <a href="https://www.facebook.com/profile.php?id=100091796694325">
            Messengeren
          </a>
          , vagy a <a href="tel:+36307886918">telefonszámunkon</a> tudtok!
        </h4>
      ) : (
        <h4>
          If you're interested in any of our additional services (pet
          photography, dog trimming/grooming), you can contact us on{" "}
          <a href="https://www.facebook.com/profile.php?id=100091796694325">
            Facebook
          </a>
          , or at <a href="tel:+36307886918">+36 (30) 788 6918</a>.
        </h4>
      )}
    </div>
  );
}

export default Prices;
