import React from "react";

function Service({ image, title, description }) {
  return (
    <div className="service">
      <img
        src={`${process.env.PUBLIC_URL}/img/services/${image}`}
        alt={image.split(".")[0]}
      />
      <h2>{title}</h2>
      <h6>{description}</h6>
    </div>
  );
}

function Services({ lang }) {
  const serviceData = {
    hu: [
      [
        "Panzió",
        "panzio.jpg",
        "Több évtizedes kutyás tapasztalattal várjuk kedvenceiket családias panziónkba!",
      ],
      [
        "Napközi",
        "napkozi.jpg",
        "Sokat dolgozik és kedvence unatkozik, netán rombol? Vagy szeretné, hogy jól szocializált legyen? Hozza el napközisnek hozzánk!",
      ],
      [
        "Fotózás",
        "fotozas.jpg",
        "Bármilyen kedvenc fotózása egy tetszőleges helyszínen! Gazdi-kutya fotózás, alomfotózás, kreatív fotók (pl.: szülinapi, karácsonyi képek, stb.)!",
      ],
      [
        "Kutyakozmetika",
        "kozmetika.jpg",
        "Kozmetika szolgáltatás kedvencének Kecskemét 20km-es körzetében!",
      ],
      [
        "Állatszitter",
        "petsitting.jpg",
        "Kedvence idős, műtötték, esetleg nem szereti a közösséget? Vállaljuk otthoni ellátását, gyógyszer beadást, etetést, bármit igény szerint!",
      ],
    ],
    en: [
      [
        "Boarding",
        "panzio.jpg",
        "With decades of experience with dogs, we welcome your pets to our friendly boarding house!",
      ],
      [
        "Daycare",
        "napkozi.jpg",
        "Do you work a lot and your pet gets bored or destructive? Do you want him to be well socialized? Bring him to our daycare!",
      ],
      [
        "Photography",
        "fotozas.jpg",
        "Pet photo shoot at any location! Dog photos with the Owner, litter photos, creative photos (e.g. birthday, Christmas, etc.)!",
      ],
      [
        "Grooming",
        "kozmetika.jpg",
        "Grooming service for your pet within 20 km (12 miles) of Kecskemét!",
      ],
      [
        "Pet-sitting",
        "petsitting.jpg",
        "Is your pet old, has had surgery, or just doesn't like the community? We can provide home care, medication, feeding, whatever you need!",
      ],
    ],
  };

  const services = serviceData[lang] || [];

  return (
    <div className="services">
      {services.map((service, index) => (
        <Service
          key={index}
          title={service[0]}
          image={service[1]}
          description={service[2]}
        />
      ))}
    </div>
  );
}

export default Services;
