import React from "react";

function Documents({ lang }) {
  return (
    <div className="documentsWrapper">
      <div className="documents">
        <div className="document">
          <h1>{lang === "hu" ? "Működési Szabályzat" : "Operation Policy"}</h1>
          <h3>
            <a
              href={`${process.env.PUBLIC_URL}/docs/mukodesi_szabalyzat.pdf`}
              download
            >
              {lang === "hu" ? "Letöltés" : "Download"}{" "}
              <i className="bi bi-file-earmark-arrow-down-fill"></i>
            </a>
          </h3>
          <h3>
            <a
              href={`${process.env.PUBLIC_URL}/docs/mukodesi_szabalyzat.pdf`}
              target="_blank"
              rel="noreferrer"
            >
              {lang === "hu" ? "Megnyitás" : "Open"}{" "}
              <i className="bi bi-file-earmark-pdf-fill"></i>
            </a>
          </h3>
        </div>

        <div className="document">
          <h1>
            {lang === "hu" ? "Átvételi Adatlap" : "Dog Transfer Contract"}
          </h1>
          <h3>
            <a
              href={`${process.env.PUBLIC_URL}/docs/atveteli_adatlap.pdf`}
              download
            >
              {lang === "hu" ? "Letöltés" : "Download"}{" "}
              <i className="bi bi-file-earmark-arrow-down-fill"></i>
            </a>
          </h3>
          <h3>
            <a
              href={`${process.env.PUBLIC_URL}/docs/atveteli_adatlap.pdf`}
              target="_blank"
              rel="noreferrer"
            >
              {lang === "hu" ? "Megnyitás" : "Open"}{" "}
              <i className="bi bi-file-earmark-pdf-fill"></i>
            </a>
          </h3>
        </div>

        {lang === "en" && (
          <div className="notice">Documents are not translated to English.</div>
        )}
      </div>
    </div>
  );
}

export default Documents;
