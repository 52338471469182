import React from "react";

const Cover = () => {
  const lang = window.location.href.split("/")[3];

  return (
    <div className="cover">
      <div className="coverImg"></div>
      <div className="coverText">
        {lang === "en" ? (
          <>
            <h1>SzuperEB</h1>
            <h2>Dog Boarding</h2>
            <h2>and Daycare</h2>{" "}
          </>
        ) : (
          <>
            <h1>SzuperEB</h1>
            <h2>Kutyapanzió</h2>
            <h2>és Napközi</h2>
          </>
        )}
      </div>
    </div>
  );
};

const About = () => {
  const lang = window.location.href.split("/")[3];

  return (
    <div className="about">
      {lang === "en" ? (
        <p>
          We have setup SzuperEB Dog Boarding and Daycare in our home located on
          the edge Helvécia, 8 kilometres (5 miles) away from Kecskemét, 5
          minutes away from the M5 freeway. We deliver the puppies from
          door-to-door, or take care of them at the Owner's house if necessary.
          We also do Dog Photography.
        </p>
      ) : (
        <p>
          Kecskeméttől 8 km-re, az M5-ös autópálya lejárótól 5 percre, Helvécia
          szélső utcájában található családi házunknál alakítottuk ki a SzuperEB
          Kutyapanziót és Napközit. Igény esetén a kutyusokat háztól-házig
          szállítjuk, illetve akár a gazdi otthonában is ellátjuk! Vállalunk
          továbbá háznál kutyakozmetikát, illetve a kis kedvencek fotózását is!
        </p>
      )}
    </div>
  );
};

function Homepage({ lang }) {
  return (
    <>
      <Cover />
      <About />
    </>
  );
}

export default Homepage;
