import { useState } from "react";
import { Link } from "react-router-dom";

const LangSwitch = () => {
  const lang = window.location.href.split("/")[3];

  function switchLang() {
    if (lang === "hu") {
      window.location.href = "/en";
    } else if (lang === "en") {
      window.location.href = "/hu";
    } else {
      window.location.href = "/en";
    }
  }

  return (
    <div className="langSwitch" onClick={switchLang}>
      <i className="bi bi-globe2"></i>
    </div>
  );
};

const Toggler = ({ toggled, setToggled }) => {
  return (
    <div className="toggler" onClick={() => setToggled(!toggled)}>
      <i className="bi bi-list"></i>
    </div>
  );
};

const Brand = () => {
  const lang = window.location.href.split("/")[3];

  return (
    <div
      className="brand"
      onClick={() => {
        if (lang === "hu") {
          window.location.href = "/";
        } else {
          window.location.href = "/en";
        }
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL}/img/szupereb-white.png`}
        alt="Home"
      />
      <h5 className="title">SzuperEB</h5>
    </div>
  );
};

const Items = ({ toggled, setToggled, lang }) => {
  return (
    <div className={`items ${toggled}`}>
      {lang === "hu" ? (
        <>
          <div
            className="btn"
            onClick={() => {
              setToggled(false);
              window.location.href = "/hu/szolgaltatasok";
            }}
          >
            <Link to="/hu/szolgaltatasok">Szolgáltatások</Link>
          </div>
          <div
            className="btn"
            onClick={() => {
              setToggled(false);
              window.location.href = "/hu/arak";
            }}
          >
            <Link to="/hu/arak">Árak</Link>
          </div>
          <div
            className="btn"
            onClick={() => {
              setToggled(false);
              window.location.href = "/hu/dokumentumok";
            }}
          >
            <Link to="/hu/dokumentumok">Dokumentumok</Link>
          </div>
        </>
      ) : (
        <>
          <div
            className="btn"
            onClick={() => {
              setToggled(false);
              window.location.href = "/en/services";
            }}
          >
            <Link to="/en/services">Services</Link>
          </div>
          <div
            className="btn"
            onClick={() => {
              setToggled(false);
              window.location.href = "/en/prices";
            }}
          >
            <Link to="/en/prices">Prices</Link>
          </div>
          <div
            className="btn"
            onClick={() => {
              setToggled(false);
              window.location.href = "/en/documents";
            }}
          >
            <Link to="/en/documents">Documents</Link>
          </div>
        </>
      )}
    </div>
  );
};

function Navbar() {
  const [toggled, setToggled] = useState(false);
  var lang = window.location.href.split("/")[3];

  if (lang === "") {
    lang = "hu";
  }

  return (
    <header className="navbar">
      <Toggler toggled={toggled} setToggled={setToggled} />
      <Brand />
      <Items toggled={toggled} setToggled={setToggled} lang={lang} />
      <LangSwitch />
    </header>
  );
}

export default Navbar;
